@import "../../node_modules/bulma/css/bulma.min.css";
// fonts
$headingFont: "Nunito", sans-serif;
$textFont: "Lato", sans-serif;

// colors
$headingBlack: rgba(33, 55, 87, 0.8);
$textBlack: rgba(37, 49, 60, 0.8);
$bgColor: #f5f3fa;
$accentColor: #00b7a8;
$kanjidexColor: #eb5757;

// general
body {
  background-color: $bgColor;
}
body.page,
header {
  background-color: #fff;
}
section {
  min-height: 100vh;
  padding: 150px 0;
}
strong {
  color: #00b7a8;
  font-weight: 600;
}

h1 {
  font-family: $headingFont;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 112%;
  letter-spacing: 0.02em;
  color: $headingBlack;
  margin-bottom: 20px;
}

p,
li {
  font-family: $textFont;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 163.15%;
  max-width: 495px;
  color: #6d7d8b;
  margin-bottom: 20px;
}

a.link-main {
  position: relative;
  display: inline-block;
  color: $accentColor;
  &::after {
    content: "";
    background: url("../assets/arrow.png") no-repeat;
    display: inline-block;
    width: 20px;
    height: 18px;
    margin-left: 5px;
    transition: all 0.3s ease-in-out;
  }
  &:hover::after {
    margin-left: 8px;
  }
}

.img-wrapper-right {
  text-align: right;
}

.img-wrapper-left {
  text-align: left;
}

.section-img {
  display: inline-block;
  max-height: 450px;
}

.divider {
  margin-bottom: 50px;
}

// navigation
.navbar {
  position: relative;
  padding: 20px 0;
  background: transparent;
  transition: background 0.5s, padding 0.1s;
  z-index: 999;
}

.onScroll {
  padding: 10px 0;
  background-color: rgba(#fff, 0.8) !important;
}

a.navbar-item {
  font-family: $textFont;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 175.7%;
  padding-left: 25px;
  color: $textBlack;
  &:hover,
  &:focus,
  &.is-active {
    color: $kanjidexColor;
  }
}

// hero
#hero {
  background-color: #ffdee9;
  background-image: linear-gradient(50deg, #ffdee9 0%, #b5fffc 100%);
  padding: 60px 0 !important;
  img {
    margin-top: 10%;
  }
  li {
    transition: all 0.3s ease-in-out;
  }
  button,
  button:focus,
  li:focus {
    outline: none !important;
  }
  li:hover {
    opacity: 0.8;
  }
  i.fa {
    background-color: $kanjidexColor;
    color: $bgColor;
    font-size: 60px;
    border-radius: 50%;
    padding: 10px;
    margin-bottom: 10px;
    height: 80px;
    width: 80px;
    vertical-align: center;
    display: inline-block;
    text-align: center;
    box-shadow: 0 0 15px rgba($kanjidexColor, 0.8);
  }
  .slider-container {
    position: relative;
  }
  .tns-outer {
    text-align: center;
  }
  .tns-item {
    text-align: center;
    img {
      border-radius: 8px;
    }
  }
  .tns-nav {
    position: absolute;
    display: inline-block;
    bottom: 0;
    text-align: center;
    margin-left: -25px;
    z-index: 9;
    button {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: rgba($kanjidexColor, 0.6);
      margin-right: 5px;
    }
    button.tns-nav-active {
      background-color: $kanjidexColor;
    }
  }
  .controls {
    margin: 0;
    padding: 0;
    list-style: none;
    left: 0;
    width: 100%;
    top: 50%;
    transform: translatey(-30%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 9;
    &:focus {
      outline: none;
    }

    li:hover {
      cursor: pointer;
    }
  }
}

// about

#about {
  position: relative;
  padding: 230px 0;
}

//features
#features {
  background-color: $bgColor;
  padding: 4% 0;
  .feature-box {
    text-align: center;
    &__icon {
      font-size: 6em;
    }
    &__text {
      h3 {
        font-size: 1.4em;
        font-weight: bold;
      }
      p {
        opacity: 0.6;
        margin-top: 4px;
        display: block;
        text-align: center;
      }
    }
  }
}

// contact
#contact {
  min-height: 30vh;
  padding: 4% 0;
  background-image: url("../assets/lilypads.png");
  p {
    font-weight: 600;
    font-size: 22px;
    line-height: 173.2%;
  }
}

#contact-form {
  padding: 0 35px;
  margin-bottom: 30px;
}

// footer
footer {
  font-family: $textFont;
  img {
    margin-right: 8px;
    margin-bottom: -2px;
    width: 40px;
  }
}

// page
header {
  min-height: 30vh;
  h1 {
    padding: 8% 0;
  }
}

.decorated-list li {
  line-height: 18px;
  list-style: disc;
}

.text-highlight {
  font-style: normal;
  border-bottom: 3px dashed rgba($kanjidexColor, 0.4);
}

// media queries

@media (max-width: 1024px) {
  section {
    min-height: 50vh;
  }
}

@media (max-width: 998px) {
  body {
    overflow-x: hidden;
  }
  section {
    min-height: 40vh;
  }
  #hero {
    text-align: center;
    p {
      display: inline-block;
      text-align: center;
    }
  }
  #about,
  #features {
    padding: 40px 25px;
  }
}

@media (min-width: 768px) {
  header {
    min-height: 20vh;
    h1 {
      margin-top: 80px;
    }
  }
  body > div.container > div > div:nth-child(1) {
    padding: 40px 45px;
  }
  .divider {
    margin-bottom: 100px;
  }

  .padding-left {
    padding-left: 25%;
  }
}

@media (max-width: 767px) {
  body {
    overflow-x: hidden;
  }

  .reverse-columns {
    flex-direction: column-reverse;
    display: flex;
  }
}

@media (max-width: 414px) {
  section {
    min-height: 70vh;
    padding: 80px 0;
  }
  h1 {
    font-size: 36px;
  }
  h3 {
    font-size: 22px;
  }
  #hero {
    i.fa {
      font-size: 30px;
      border-radius: 50%;
      padding: 5px;
      height: 40px;
      width: 40px;
    }
    .tns-item {
      img {
        max-height: 450px;
      }
    }
  }
  #about,
  #features {
    padding: 20px 15px;
  }
  header {
    min-height: 15vh;
    h1 {
      margin-top: 80px;
    }
  }
  body > div.container > div > div:nth-child(1) {
    padding: 0 45px;
  }
}
